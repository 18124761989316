import {useEffect, useContext} from 'react';
import once from 'lodash.once';
import isEmpty from 'lodash.isempty';
import {AppContext} from '../contexts/AppContext';
import {CheckoutContext} from '../contexts/CheckoutContext';
import mapFundsForAnalytics from './mapFundsForAnalytics';
import {getTotal} from './getAmounts';

const trackCheckoutStarted = once(({orderNumber, quantity, cashFund}) => {
  window.analytics.track({
    event: 'Checkout Started',
    properties: {
      order_id: orderNumber || null,
      affiliation: cashFund.affiliation,
      value: Number(getTotal(quantity, cashFund).toFixed(2)),
      quantity: Number(quantity),
      revenue: Number(getTotal(quantity, cashFund).toFixed(2)),
      tax: null,
      currency: 'USD',
      products: mapFundsForAnalytics({cashFund, quantity}),
      wishlist_id: cashFund.memberId || undefined,
    },
  });
});

const useTrackCheckoutStarted = () => {
  const {cashFund, quantity} = useContext(AppContext);
  const {orderNumber} = useContext(CheckoutContext);

  useEffect(() => {
    const isCashFundLoaded = !isEmpty(cashFund);

    if (isCashFundLoaded) {
      trackCheckoutStarted({orderNumber, quantity, cashFund});
    }
  }, [quantity, cashFund, orderNumber]);
};

export default useTrackCheckoutStarted;
