import analyticsWrapper from '@xo-union/tk-analytics';
import isMobile from './isMobile';

const track = ({event, ...props}) => {
  analyticsWrapper.track(event, {
    platform: isMobile() ? 'mobile' : 'web',
    ...props,
  });
};

const trackCashCheckout = props =>
  track({
    product: 'guest facing registry',
    ...props,
  });

export default trackCashCheckout;
