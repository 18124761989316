import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import Spinner from '@xo-union/tk-component-spinner';
import {AppContext} from './contexts/AppContext';
import {CheckoutContext} from './contexts/CheckoutContext';
import fetchCashFund from './utils/fetchCashFund';
import fetchCouple from './utils/fetchCouple';
import isUUID from './utils/isUUID';
import isValidUrl from './utils/isValidUrl';
import redirect404 from './utils/redirect404';
import Checkout from './views/Checkout';
import Confirmation from './views/Confirmation';
import style from './style.scss';

const App = () => {
  const {setCashFund, setCouple, setQuantity, setRedirect} = useContext(
    AppContext,
  );
  const {showConfirmation} = useContext(CheckoutContext);
  const [isLoading, setIsLoading] = useState(false);
  const {cashFundId, coupleId} = useParams();
  const query = new URLSearchParams(useLocation().search);
  const quantity = query.get('quantity');
  const redirect = query.get('redirect');

  useEffect(() => {
    const initialize = async () => {
      setQuantity(quantity);
      setRedirect(redirect);

      if (
        !cashFundId ||
        !isUUID(cashFundId) ||
        !coupleId ||
        !quantity ||
        !redirect ||
        !isValidUrl(redirect)
      )
        redirect404();

      setIsLoading(true);

      const fund = await fetchCashFund(cashFundId);
      setCashFund({...fund, affiliation: 'Cash Fund'});

      if (fund.isArchived) redirect404();

      const coupleInfo = await fetchCouple(fund.memberId);
      setCouple(coupleInfo);

      setIsLoading(false);
    };

    initialize();
  }, [
    cashFundId,
    coupleId,
    quantity,
    redirect,
    setCashFund,
    setCouple,
    setQuantity,
    setRedirect,
  ]);

  if (isLoading)
    return (
      <div className={style['checkout-spinner-container']}>
        <Spinner className={style['checkout-spinner']} />
      </div>
    );

  return (
    <div className={style['checkout-container']}>
      {showConfirmation ? <Confirmation /> : <Checkout />}
    </div>
  );
};

export default App;
