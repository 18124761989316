import * as Honeybadger from 'honeybadger-js';
import create from '@xo-union/tab-accessibility';
import {PageletContainer} from '@xo-union/pagelet';

if (window && window.analytics) window.analytics.page();

const tabAccessibility = create();

/* eslint-disable-next-line */
new PageletContainer('my-pagelet-container-id', {
  errorPagelet: null,
  props: {},
});

tabAccessibility.mount();

Honeybadger.configure({
  apiKey: process.env.HONEYBADGER_API_KEY,
  environment: process.env.NODE_ENV,
  revision: process.env.GIT_REVISION,
});

window.HoneyBadger = Honeybadger;

// 03/13/18 Sadly, only Chrome supports it.
// We could polyfill it if we want to. https://www.npmjs.com/package/core-js
// https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onunhandledrejection
window.onunhandledrejection = event => Honeybadger.notify(event.reason);

export default Honeybadger;
