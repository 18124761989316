import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import gpt from '@tkww/xo.ads.gpt';
import {H4, Subhead, Overline} from '@xo-union/tk-ui-typography';
import Icon from '@xo-union/tk-component-icons';
import isMobile from '../../utils/isMobile';
import style from './style.scss';

const Ad = ({handleAdShown}) => {
  const adElementRef = useRef();

  useEffect(() => {
    const deviceType = isMobile() ? 'Mobile' : 'Desktop';
    const adPosition = isMobile() ? 'm300bottom' : '728bottom';
    const applicationName = 'Registry';
    const configPresets = new gpt.configPresets.TheKnotCore({
      applicationName,
    });

    gpt.initialize(configPresets);
    gpt.addAsyncSlot({
      idSelector: 'cash-fund-ad',
      sync: true,
      sizes: [[1, 1]],
      targeting: [
        ['pos', adPosition],
        ['sid', 'cash-fund'],
        ['page-type', 'checkout'],
        ['wedding_roles', 'wedding-guest'],
        ['wedding-roles', 'wedding-guest'],
        ['mtdevicetype', deviceType.toLowerCase()],
        ['device-type', deviceType.toLowerCase()],
      ],
      postRenderCallback: (_, success) => {
        if (success) {
          adElementRef.current.classList.remove(style.hidden);
          handleAdShown();
        }
      },
    });

    gpt.renderAds();
  }, []);

  const AdHeaderComponent = isMobile() ? Subhead : H4;

  return (
    <div
      className={`${style['ad-container']} ${style.hidden}`}
      ref={adElementRef}>
      <div className={style['ad-header']}>
        <AdHeaderComponent as="span">Be ready to celebrate</AdHeaderComponent>
        <span className={style.icon}>
          <Icon name="wedding_vision_selected" size="sm" />
        </span>
      </div>
      <Overline className={style['ad-label']}>ADVERTISEMENT</Overline>
      <div id="cash-fund-ad" />
    </div>
  );
};

Ad.propTypes = {
  handleAdShown: PropTypes.func.isRequired,
};

export default Ad;
