import React, {useContext, useState} from 'react';
import {Link} from '@xo-union/tk-component-buttons';
import {FlagContext} from '@unleash/proxy-client-react';
import {FIXED_FUND, FEATURES} from '../../constants';
import {AppContext} from '../../contexts/AppContext';
import {CheckoutContext} from '../../contexts/CheckoutContext';
import generateTestId from '../../utils/generateTestId';
import getImageSrc from '../../utils/getImageSrc';
import style from './style.scss';
import useTrackOrderCompleted from '../../utils/trackOrderCompleted';
import Ad from '../../components/Ad';

const Confirmation = () => {
  const {
    cashFund: {fundType, imageUrl, name},
    couple: {fianceFirstName, firstName},
    quantity,
    redirect,
  } = useContext(AppContext);
  const {giftAmount, orderNumber, total, transactionFee} = useContext(
    CheckoutContext,
  );
  const image = getImageSrc(imageUrl);
  const {isEnabled} = useContext(FlagContext);
  const showOfferAd = isEnabled(FEATURES.OFFER_AD_SECTION);
  const [adVisible, setAdVisible] = useState(false);
  const handleAdShown = () => {
    setAdVisible(true);
  };
  useTrackOrderCompleted(orderNumber, total, giftAmount, transactionFee);
  return (
    <div className={style.container}>
      <h1
        className={style['purchase-confirmation-title']}
        data-testid={generateTestId('purchase-confirmation-summary-title')}>
        Thank You!
      </h1>

      <div
        className={style['purchase-confirmation-subtitle']}
        data-testid={generateTestId('purchase-confirmation-summary-subtitle')}>
        {firstName}&nbsp;
        {fianceFirstName !== '' && `and ${fianceFirstName}`}&nbsp; will be
        notified of your gift.
      </div>

      <div
        className={style['purchase-confirmation-summary']}
        data-testid={generateTestId('purchase-confirmation-summary-container')}>
        <div
          className={style['order-number']}
          data-testid={generateTestId(
            'purchase-confirmation-summary-order-number',
          )}>
          Your Order Number: {orderNumber}
        </div>

        <div
          className={style['order-image-name-container']}
          data-testid={generateTestId('purchase-confirmation-summary')}>
          <div
            className={style['order-image']}
            data-testid={generateTestId(
              'purchase-confirmation-summary-order-image',
            )}
            style={{
              backgroundImage: `url(${image})`,
            }}
          />

          <div
            data-testid={generateTestId('purchase-confirmation-summary-name')}>
            {name}
          </div>
        </div>

        <div
          className={style['order-summary']}
          data-testid={generateTestId('purchase-confirmation-summary')}>
          {fundType === FIXED_FUND && (
            <div
              className={style['order-amount-container']}
              data-testid={generateTestId(
                'purchase-confirmation-summary-quantity-container',
              )}>
              <span>Quantity:</span>
              <span
                data-testid={generateTestId(
                  'purchase-confirmation-summary-quantity',
                )}>
                {quantity}
              </span>
            </div>
          )}

          <div
            className={style['order-amount-container']}
            data-testid={generateTestId(
              'purchase-confirmation-summary-gift-amount-container',
            )}>
            <span>Gift Amount:</span>
            <span
              data-testid={generateTestId(
                'purchase-confirmation-summary-gift-amount',
              )}>
              {giftAmount}
            </span>
          </div>

          <div
            className={style['order-amount-container']}
            data-testid={generateTestId(
              'purchase-confirmation-summary-transaction-fee-container',
            )}>
            <span>Transaction Fee:</span>
            <span
              data-testid={generateTestId(
                'purchase-confirmation-summary-transaction-fee',
              )}>
              + {transactionFee}
            </span>
          </div>

          <div
            className={style['order-amount-container']}
            data-testid={generateTestId(
              'purchase-confirmation-summary-total-container',
            )}>
            <span className={style['order-summary-total']}>Total:</span>
            <span
              className={style['order-summary-total']}
              data-testid={generateTestId(
                'purchase-confirmation-summary-total',
              )}>
              {total}
            </span>
          </div>
        </div>
      </div>

      <div
        className={style['purchase-confirmation-subtext']}
        data-testid={generateTestId('purchase-confirmation-summary-info')}>
        The charge will appear as &ldquo;The Knot Registry&rdquo; on your credit
        card statement.
      </div>

      {showOfferAd && <Ad handleAdShown={handleAdShown} />}

      <div className={style['link-container']}>
        <Link
          className={style['purchase-confirmation-redirect']}
          color={showOfferAd && adVisible ? 'tertiary' : 'primary'}
          data-testid={generateTestId('purchase-confirmation-summary-back-cta')}
          href={redirect}
          size="md">
          Back to Registry
        </Link>
      </div>
    </div>
  );
};

export default Confirmation;
