import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox} from '@xo-union/tk-component-switches';

const FormCheckbox = ({field: {value, ...field}, ...rest}) => (
  <Checkbox {...field} {...rest} value={value.toString()} checked={value} />
);

FormCheckbox.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.bool.isRequired,
  }).isRequired,
};

export default FormCheckbox;
