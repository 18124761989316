import Honeybadger from 'honeybadger-js';
import trackCashCheckout from './trackCashCheckout';

const getStripeTokenId = async (values, errorCb) => {
  window.Stripe.setPublishableKey(process.env.STRIPE_PUBLIC_API_KEY);

  return new Promise((resolve, reject) =>
    window.Stripe.card.createToken(
      {
        address_city: values.city,
        address_country: 'US',
        address_line1: values.address,
        address_state: values.state,
        address_zip: values.zip,
        cvc: values.cvv,
        exp_month: values.expiration.split('/')[0],
        exp_year: values.expiration.split('/')[1],
        name: values.name,
        number: values.card,
      },
      (status, response) => {
        if (status === 200) return resolve(response.id);

        Honeybadger.notify(response.error.message, 'getStripeTokenId');

        trackCashCheckout({
          event: 'Cash Guest Error',
          source: 'cash checkout form',
          stripeReason: response.error.message,
        });

        errorCb();

        return reject(new Error(response.error.message));
      },
    ),
  );
};

export default getStripeTokenId;
