import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from '../MaskedInput';

const FormField = ({field, form, fullStoryMask, ...rest}) => {
  const {errors, setFieldTouched, touched} = form;
  const showError = touched[field.name] && errors[field.name];

  return (
    <MaskedInput
      {...field}
      {...rest}
      fullStoryMask={fullStoryMask}
      guide={false}
      onInput={() => setFieldTouched(field.name, true, false)}
      state={showError ? 'invalid' : 'neutral'}
      subText={showError ? errors[field.name] : null}
    />
  );
};

FormField.propTypes = {
  field: PropTypes.shape().isRequired,
  fullStoryMask: PropTypes.bool,
  form: PropTypes.shape({
    errors: PropTypes.shape(),
    setFieldTouched: PropTypes.func.isRequired,
    touched: PropTypes.shape(),
  }).isRequired,
};

FormField.defaultProps = {
  fullStoryMask: true,
};

export default FormField;
