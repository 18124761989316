import mapFundsForAnalytics from './mapFundsForAnalytics';

const trackGiftNoteAdded = ({
  orderNumber,
  cashFund,
  // eslint-disable-next-line camelcase
  character_count,
  total,
}) => {
  window.analytics.track({
    event: 'Gift note added',
    properties: {
      order_id: orderNumber || null,
      character_count,
      affiliation: cashFund.affiliation,
      total,
      products: mapFundsForAnalytics({cashFund}),
      wishlist_id: cashFund.memberId || undefined,
    },
  });
};
export default trackGiftNoteAdded;
