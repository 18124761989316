import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Icon from '@xo-union/tk-component-icons';
import generateTestId from '../../utils/generateTestId';
import trackBreadcrumbClick from '../../utils/trackBreadcrumbClick';
import {CheckoutContext} from '../../contexts/CheckoutContext';
import {AppContext} from '../../contexts/AppContext';
import style from './style.scss';

const BackCta = ({redirect}) => {
  const {total, orderNumber} = useContext(CheckoutContext);
  const {cashFund} = useContext(AppContext);

  return (
    <a
      className={style['back-cta']}
      data-testid={generateTestId('back-cta')}
      href={redirect}
      onClick={() => trackBreadcrumbClick({orderNumber, cashFund, total})}>
      <Icon
        className={style['back-icon']}
        data-testid={generateTestId('back-cta-icon')}
        name="back"
        size="sm"
      />
      <span
        className={style['back-label']}
        data-testid={generateTestId('back-cta-text')}>
        Registry
      </span>
    </a>
  );
};

BackCta.propTypes = {
  redirect: PropTypes.string.isRequired,
};

export default BackCta;
