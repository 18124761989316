import '@xo-union/tk-ui-essentials';
import './honeybadger';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import FlagProvider from '@unleash/proxy-client-react';
import {AppContextProvider} from './contexts/AppContext';
import {CheckoutContextProvider} from './contexts/CheckoutContext';
import {ErrorContextProvider} from './contexts/ErrorContext';
import Redirect404 from './utils/redirect404';
import App from './App';
import {UNLEASH_CONFIG} from './unleash';

ReactDOM.render(
  <FlagProvider config={UNLEASH_CONFIG}>
    <AppContextProvider>
      <ErrorContextProvider>
        <CheckoutContextProvider>
          <BrowserRouter>
            <Routes>
              <Route
                element={<App />}
                exact
                path="/registry/checkout/:coupleId/:cashFundId"
              />
              <Route path="*" element={<Redirect404 />} />
            </Routes>
          </BrowserRouter>
        </CheckoutContextProvider>
      </ErrorContextProvider>
    </AppContextProvider>
  </FlagProvider>,
  document.getElementById('root'),
);
