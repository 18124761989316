import React from 'react';
import PropTypes from 'prop-types';
import RTMMaskedInput from 'react-text-mask';
import {Field} from '@xo-union/tk-component-fields';
import {compose} from '@xo-union/react-css-modules';

/**
 * Applies text masking functionality to Union `Field` input.
 * Accepts all properties of react-text-mask https://github.com/text-mask/text-mask/tree/master/react
 */

const MaskedInput = ({mask, fullStoryMask, ...rest}) => {
  return (
    <RTMMaskedInput
      {...rest}
      mask={mask}
      render={(ref, props) => (
        <Field
          ref={ref}
          {...props}
          classes={compose({
            input: fullStoryMask ? 'fs-mask' : '',
          })}
        />
      )}
    />
  );
};

MaskedInput.propTypes = {
  fullStoryMask: PropTypes.bool.isRequired,
  mask: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func,
    PropTypes.bool,
    PropTypes.shape({
      mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
      pipe: PropTypes.func,
    }),
  ]),
};

MaskedInput.defaultProps = {
  mask: false,
};

export default MaskedInput;
