import React from 'react';
import PropTypes from 'prop-types';
import {Textarea} from '@xo-union/tk-component-fields';
import {compose} from '@xo-union/react-css-modules';

const FormTextarea = ({
  field,
  form: {touched, errors},
  handleBlur,
  ...rest
}) => {
  const showError = touched[field.name] && errors[field.name];

  return (
    <Textarea
      {...field}
      {...rest}
      classes={compose({
        input: 'fs-mask',
      })}
      onBlur={e => {
        field.onBlur(e);
        handleBlur(e);
      }}
      state={showError ? 'invalid' : 'neutral'}
      subText={showError ? errors[field.name] : null}
    />
  );
};

FormTextarea.propTypes = {
  handleBlur: PropTypes.func.isRequired,
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape(),
    errors: PropTypes.shape(),
  }).isRequired,
};

export default FormTextarea;
