import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {Form} from 'formik';
import BrandLogo from '@xo-union/component-brand-logo';
import {H2} from '@xo-union/tk-ui-typography';
import {AppContext} from '../../contexts/AppContext';
import {ErrorContext} from '../../contexts/ErrorContext';
import BackCta from '../BackCta';
import CheckoutFormFields from '../CheckoutFormFields';
import CheckoutSummary from '../CheckoutSummary';
import ContactInformation from '../ContactInformation';
import CreditCardInformation from '../CreditCardInformation';
import ErrorAlert from '../ErrorAlert';
import PurchaseButton from '../PurchaseButton';
import style from './style.scss';
import trackGiftNoteAdded from '../../utils/trackGiftNoteAdded';
import {CheckoutContext} from '../../contexts/CheckoutContext';

const CheckoutForm = ({isPurchaseDisabled, setFieldTouched}) => {
  const {redirect, cashFund} = useContext(AppContext);
  const {cardError} = useContext(ErrorContext);
  const {orderNumber, total} = useContext(CheckoutContext);
  const [note, setNote] = useState('');

  const handleNoteBlur = e => {
    if (note !== e.currentTarget.value && e?.currentTarget?.value?.length > 0) {
      setNote(e.currentTarget.value);
      trackGiftNoteAdded({
        orderNumber,
        cashFund,
        character_count: e.currentTarget.value.length,
        total,
      });
    }
  };

  return (
    <Form>
      <div className={style['checkout-form-logo-container']}>
        <BackCta redirect={redirect} />
        <BrandLogo
          name="theknot"
          color="primary"
          size="lg"
          id="logo"
          className={style['checkout-form-logo']}
        />
      </div>
      <H2 className={style['checkout-form-container-header']}>Gift Checkout</H2>
      {cardError.message !== '' && (
        <ErrorAlert message={cardError.message} subText={cardError.subText} />
      )}
      <div className={style['checkout-form-view']}>
        <CheckoutSummary
          className={style['checkout-form-summary-mobile']}
          setFieldTouched={setFieldTouched}
        />
        <div className={style['checkout-form-fields-section']}>
          <CreditCardInformation />
          <CheckoutFormFields handleNoteBlur={handleNoteBlur} />
        </div>
        <div className={style['checkout-form-details-section']}>
          <CheckoutSummary
            className={style['checkout-form-summary-desktop']}
            setFieldTouched={setFieldTouched}
          />
          <PurchaseButton isDisabled={isPurchaseDisabled} />
          <ContactInformation />
        </div>
      </div>
    </Form>
  );
};

CheckoutForm.propTypes = {
  isPurchaseDisabled: PropTypes.bool.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
};

export default CheckoutForm;
