import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@xo-union/tk-component-icons';
import generateTestId from '../../utils/generateTestId';
import style from './style.scss';

const ErrorAlert = ({message, subText}) => (
  <div
    className={style['error-message-container']}
    data-testid={generateTestId('error-alert-container')}>
    <Icon
      className={style['error-message-icon']}
      data-testid={generateTestId('error-alert-icon')}
      name="error"
      size="md"
    />
    <div className={style['error-message-wrapper']}>
      <span data-testid={generateTestId('error-alert-message')}>{message}</span>
      {subText !== '' && (
        <span data-testid={generateTestId('error-alert-subtext')}>
          {subText}
        </span>
      )}
    </div>
  </div>
);

ErrorAlert.propTypes = {
  message: PropTypes.string.isRequired,
  subText: PropTypes.string,
};

ErrorAlert.defaultProps = {
  subText: '',
};

export default ErrorAlert;
