const fetchCouple = async memberId => {
  const response = await fetch(
    `${process.env.CORE_API_HOST}/v2/members/${memberId}/public-summary?eventType=wedding`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return response.json();
};

export default fetchCouple;
