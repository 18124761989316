const createPurchase = async purchase => {
  const response = await fetch(`${process.env.CASH_API_HOST}/v1/purchases`, {
    body: JSON.stringify(purchase),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return {
    body: await response.json(),
    status: response.status,
  };
};

export default createPurchase;
