const mapFundsForAnalytics = ({cashFund, quantity}) => ({
  brand: null,
  category: null,
  image_url: cashFund.imageUrl || '',
  name: cashFund.name,
  price:
    cashFund.fundType === 'unlimited'
      ? Number(quantity)
      : Number((cashFund.priceCents / 100).toFixed(2)),
  product_id: cashFund.id || '',
  sku: null,
  url: null,
  variant: cashFund.fundType,
});

export default mapFundsForAnalytics;
