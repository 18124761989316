import mapFundsForAnalytics from './mapFundsForAnalytics';

const trackBreadcrumbClick = ({orderNumber, cashFund, total}) => {
  window.analytics.track({
    event: 'Cart breadcrumb interaction',
    properties: {
      order_id: orderNumber || null,
      total,
      affiliation: cashFund.affiliation,
      products: mapFundsForAnalytics({cashFund}),
      wishlist_id: cashFund.memberId || undefined,
      selection: 'back to registry',
    },
  });
};
export default trackBreadcrumbClick;
