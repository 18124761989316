import React, {createContext, useState} from 'react';
import PropTypes from 'prop-types';

export const AppContext = createContext({
  cashFund: {},
  cashFundId: undefined,
  couple: {},
  coupleId: undefined,
  quantity: undefined,
  redirect: '',
  setCashFund: () => {},
  setCouple: () => {},
  setQuantity: () => {},
  setRedirect: () => {},
});

export const AppContextProvider = ({children}) => {
  const [cashFund, setCashFund] = useState({});
  const [couple, setCouple] = useState({});
  const [quantity, setQuantity] = useState(undefined);
  const [redirect, setRedirect] = useState('');

  return (
    <AppContext.Provider
      value={{
        cashFund,
        couple,
        quantity,
        redirect,
        setCashFund,
        setCouple,
        setQuantity,
        setRedirect,
      }}>
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
