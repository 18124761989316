import React, {useContext, useState} from 'react';
import {Field, useFormikContext} from 'formik';
import get from 'lodash.get';
import {createAutoCorrectedDatePipe} from 'text-mask-addons';
import {FormRow, FormColumn} from '@xo-union/tk-component-form-grid';
import StateSelect from '@tkww/registry-component-state-select';
import {Tooltip, TooltipContainer} from '@xo-union/tk-component-tooltips';
import PropTypes from 'prop-types';
import {AppContext} from '../../contexts/AppContext';
import {CheckoutContext} from '../../contexts/CheckoutContext';
import FormField from '../FormField';
import FormTextarea from '../FormTextarea';
import generateTestId from '../../utils/generateTestId';
import trackPaymentInfoEntered from '../../utils/trackPaymentInfoEntered';
import style from './style.scss';
import trackBillingAddressEntered from '../../utils/trackBillingAddressEntered';

const CheckoutFormFields = ({handleNoteBlur}) => {
  const {
    couple: {fianceFirstName, firstName},
    cashFund,
  } = useContext(AppContext);
  const {orderNumber} = useContext(CheckoutContext);
  const {errors} = useFormikContext();
  const hasPaymentError = ['card', 'cvv', 'expiration'].some(field =>
    get(errors, field),
  );
  const hasBillingAddressError = [
    'address',
    'city',
    'state',
    'zip',
    'email',
  ].some(field => get(errors, field));
  const [isTooltipShowing, setIsTooltipShowing] = useState(false);
  const handleBlur = () => {
    setIsTooltipShowing(false);
    if (!hasPaymentError) trackPaymentInfoEntered({orderNumber, cashFund});
  };

  const handleBillingBlur = () => {
    if (!hasBillingAddressError) {
      trackBillingAddressEntered({orderNumber, cashFund});
    }
  };

  return (
    <>
      <FormRow>
        <FormColumn xs="12" md="6">
          <Field
            component={FormField}
            data-testid={generateTestId('form-card-number')}
            inputMode="numeric"
            label="Card Number"
            mask={[
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            name="card"
            type="text"
          />
        </FormColumn>

        <FormColumn xs="6" md="3">
          <Field
            component={FormField}
            data-testid={generateTestId('form-card-expiration')}
            inputMode="numeric"
            label="MM/YY"
            mask={{
              mask: [/\d/, /\d/, '/', /\d/, /\d/],
              pipe: createAutoCorrectedDatePipe('mm/yy'),
            }}
            name="expiration"
            type="text"
          />
        </FormColumn>
        <FormColumn
          xs="6"
          md="3"
          className={style['cvv-container']}
          onFocus={() => setIsTooltipShowing(true)}
          onBlur={handleBlur}>
          <Field
            component={FormField}
            data-testid={generateTestId('form-card-cvv')}
            inputMode="numeric"
            label="CVV"
            name="cvv"
            type="text"
          />
          {isTooltipShowing && (
            <TooltipContainer
              className={style['tooltip-container']}
              data-testid={generateTestId('form-card-cvv-tooltip-container')}>
              <Tooltip data-testid={generateTestId('form-card-cvv-tooltip')}>
                <div className={style['credit-card-content']}>
                  <div className={style['three-digit-card']}>
                    <span className={style['credit-card-name']}>
                      Mastercard/Visa/Discover
                    </span>
                    <span className={style['credit-card-details']}>
                      3-Digit security code on back
                    </span>
                    <div className={style['card-image']} />
                  </div>
                  <div className={style['four-digit-card']}>
                    <span className={style['credit-card-name']}>AMEX</span>
                    <span className={style['credit-card-details']}>
                      4-Digit security code on front
                    </span>
                    <div className={style['card-image']} />
                  </div>
                </div>
              </Tooltip>
            </TooltipContainer>
          )}
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn xs="12">
          <Field
            component={FormField}
            data-testid={generateTestId('form-name')}
            inputMode="text"
            label="Cardholder Name"
            name="name"
            type="text"
            mask={s => Array.from(s).map(() => /[a-z.'-\s]/i)}
            guide={false}
          />
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn xs="12" onBlur={handleBillingBlur}>
          <FormRow>
            <FormColumn xs="12">
              <Field
                component={FormField}
                data-testid={generateTestId('form-address')}
                inputMode="text"
                label="Billing Address"
                name="address"
                type="text"
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn xs="12" md="4">
              <Field
                component={FormField}
                data-testid={generateTestId('form-city')}
                label="City"
                name="city"
                type="text"
              />
            </FormColumn>
            <FormColumn xs="12" md="4">
              <Field
                component={StateSelect}
                data-testid={generateTestId('form-state')}
                label="State"
                name="state"
                className="fs-mask"
                required
                useAbbreviation
              />
            </FormColumn>
            <FormColumn xs="12" md="4">
              <Field
                component={FormField}
                data-testid={generateTestId('form-zip')}
                inputMode="numeric"
                label="Zip Code"
                maxLength="7"
                name="zip"
                type="text"
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn xs="12" md="6">
              <Field
                component={FormField}
                data-testid={generateTestId('form-country')}
                disabled
                label="Country"
                name="country"
                type="text"
                value="United States"
              />
            </FormColumn>
            <FormColumn xs="12" md="6">
              <Field
                component={FormField}
                data-testid={generateTestId('form-email')}
                label="Email Address"
                name="email"
                type="text"
              />
            </FormColumn>
          </FormRow>
        </FormColumn>
      </FormRow>
      <>
        <div className={style['checkout-form-divider']} />
        {firstName && (
          <p>
            Say Congratulations to {firstName}
            {fianceFirstName !== '' && ` & ${fianceFirstName}`}! (optional)
          </p>
        )}

        <Field
          component={FormTextarea}
          data-testid={generateTestId('form-message')}
          label="Your message here..."
          name="note"
          rows={5}
          subText="You can write up to 500 characters."
          handleBlur={handleNoteBlur}
        />
      </>
    </>
  );
};

CheckoutFormFields.propTypes = {
  handleNoteBlur: PropTypes.func.isRequired,
};

export default CheckoutFormFields;
