import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Field, ErrorMessage} from 'formik';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {compose} from '@xo-union/react-css-modules';
import FormCheckbox from '../FormCheckbox';
import FormField from '../FormField';
import {FIXED_FUND, UNLIMITED_FUND} from '../../constants';
import {AppContext} from '../../contexts/AppContext';
import {CheckoutContext} from '../../contexts/CheckoutContext';
import generateTestId from '../../utils/generateTestId';
import getImageSrc from '../../utils/getImageSrc';
import useTrackCheckoutStarted from '../../utils/useTrackCheckoutStarted';
import style from './style.scss';

const AUTOCOMPLETE = 'new-password'; // Chrome "bug" that ignores autocomplete=off in an attempt to be fancy

const CheckoutSummary = ({className, setFieldTouched}) => {
  const {
    cashFund: {
      fundType,
      imageUrl,
      name,
      segmentsReceived,
      segmentsRequested,
      priceCents,
    },
  } = useContext(AppContext);
  const {giftAmount, total, transactionFee} = useContext(CheckoutContext);
  const image = getImageSrc(imageUrl);

  useEffect(() => {
    setFieldTouched('quantity', true);
  }, [setFieldTouched]);

  useTrackCheckoutStarted();

  return (
    <div
      className={className}
      data-testid={generateTestId('summary-container')}>
      <p className={style['checkout-summary-title']}>Order Summary</p>
      <div className={style['checkout-summary-card-container']}>
        <div
          className={style['checkout-summary-card-image']}
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
        <div
          className={style['checkout-summary-card-details']}
          data-testid={generateTestId('fund-details-container')}>
          <span className={style['checkout-summary-card-name']}>{name}</span>
          {segmentsRequested === 0 || fundType === UNLIMITED_FUND ? (
            <span className={style['checkout-summary-card-any-amount']}>
              Gift any amount!
            </span>
          ) : (
            <div>
              <span className={style['checkout-summary-card-price']}>
                ${(priceCents / 100).toFixed(2)}
              </span>
              <span className={style['checkout-summary-card-needs-amount']}>
                NEEDS: {Math.max(0, segmentsRequested - segmentsReceived)}
              </span>
            </div>
          )}
        </div>
      </div>
      {fundType === FIXED_FUND ? (
        <>
          <div
            className={style['checkout-summary-item-container']}
            data-testid={generateTestId('quantity')}>
            <span className={style['checkout-summary-item-label']}>
              Quantity:
            </span>
            <Field
              component={props => (
                <FormField {...props} fullStoryMask={false} />
              )}
              classes={compose({
                input: style['checkout-summary-field-input'],
                label: style['checkout-summary-field-input-label'],
                'sub-text': style['checkout-summary-field-input-subtext'],
              })}
              inputMode="numeric"
              label="Gift amount"
              name="quantity"
              type="text"
            />
          </div>
          <div
            className={style['checkout-summary-item-container']}
            data-testid={generateTestId('gift-amount-container')}>
            <span>Gift Amount:</span>
            <span data-testid={generateTestId('gift-amount')}>
              {giftAmount}
            </span>
          </div>
        </>
      ) : (
        <div
          className={style['checkout-summary-item-container']}
          data-testid={generateTestId('gift-amount-container')}>
          <span className={style['checkout-summary-item-label']}>
            Gift Amount:
          </span>
          <Field
            autoComplete={AUTOCOMPLETE}
            component={FormField}
            fullStoryMask={false}
            classes={compose({
              input: style['checkout-summary-field-input'],
              label: style['checkout-summary-field-input-label'],
              'sub-text': style['checkout-summary-field-input-subtext'],
            })}
            data-testid={generateTestId('gift-amount')}
            inputMode="numeric"
            label="Gift amount"
            mask={
              fundType !== FIXED_FUND &&
              createNumberMask({
                integerLimit: 4,
              })
            }
            name="quantity"
            type="text"
          />
        </div>
      )}
      {!Number.isNaN(transactionFee) && (
        <div
          className={style['checkout-summary-item-container']}
          data-testid={generateTestId('transaction-fee-container')}>
          <span>Transaction fee:</span>
          <span data-testid={generateTestId('transaction-fee')}>
            {transactionFee}
          </span>
        </div>
      )}
      <div className={style['checkout-summary-divider']} />
      {!Number.isNaN(total) && (
        <div
          className={cx(
            style['checkout-summary-item-container'],
            style['checkout-summary-item-total'],
          )}
          data-testid={generateTestId('total-container')}>
          <span>Total:</span>
          <span data-testid={generateTestId('total')}>{total}</span>
        </div>
      )}
      <Field
        component={FormCheckbox}
        data-testid={generateTestId('terms-checkbox')}
        name="isTermsAccepted">
        <div
          className={style['checkout-summary-terms-and-conditions']}
          data-testid={generateTestId('terms-and-conditions')}>
          <span>
            I have read and agree to The Knot&apos;s &nbsp;
            <a
              href="https://www.theknot.com/terms-and-conditions"
              rel="noopener noreferrer"
              target="_blank">
              Terms of Use
            </a>
            &nbsp; (including the cash gifting terms) and &nbsp;
            <a
              href="https://www.theknot.com/privacy-policy"
              rel="noopener noreferrer"
              target="_blank">
              Privacy Policy
            </a>
            .
          </span>
        </div>
        <ErrorMessage
          data-testid={generateTestId('terms-error-container')}
          name="isTermsAccepted">
          {message => (
            <span
              className={style['error-message']}
              data-testid={generateTestId('terms-error')}>
              {message}
            </span>
          )}
        </ErrorMessage>
      </Field>
    </div>
  );
};

CheckoutSummary.propTypes = {
  className: PropTypes.string,
  setFieldTouched: PropTypes.func.isRequired,
};

CheckoutSummary.defaultProps = {
  className: '',
};

export default CheckoutSummary;
