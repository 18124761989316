import React from 'react';
import style from './style.scss';

const CREDIT_CARD_SPRITE_AMEX =
  'https://media-api.xogrp.com/images/08e02c92-7be0-4688-bce2-731ac20da45a';
const CREDIT_CARD_SPRITE_DISCOVER =
  'https://media-api.xogrp.com/images/8a10fbab-ce18-4da4-9135-50faf090d658';
const CREDIT_CARD_SPRITE_MASTERCARD =
  'https://media-api.xogrp.com/images/0d6385af-a2cb-4d89-9218-49fa50d21fa6';
const CREDIT_CARD_SPRITE_VISA =
  'https://media-api.xogrp.com/images/bf82431c-59c5-417c-b1d5-2f56809d0758';

const CreditCardInformation = () => (
  <>
    <p>Credit Card Information</p>
    <div>
      <span
        className={style['credit-card-sprite']}
        style={{
          backgroundImage: `url(${CREDIT_CARD_SPRITE_VISA})`,
        }}
      />
      <span
        className={style['credit-card-sprite']}
        style={{
          backgroundImage: `url(${CREDIT_CARD_SPRITE_MASTERCARD})`,
        }}
      />
      <span
        className={style['credit-card-sprite']}
        style={{
          backgroundImage: `url(${CREDIT_CARD_SPRITE_AMEX})`,
        }}
      />
      <span
        className={style['credit-card-sprite']}
        style={{
          backgroundImage: `url(${CREDIT_CARD_SPRITE_DISCOVER})`,
        }}
      />
    </div>
  </>
);

export default CreditCardInformation;
