import React, {createContext, useState} from 'react';
import PropTypes from 'prop-types';

export const ErrorContext = createContext({
  cardError: '',
  setCardError: () => {},
});

export const INITIAL_CARD_ERROR_STATE = {
  message: '',
  subText: '',
};

export const ErrorContextProvider = ({children}) => {
  const [cardError, setCardError] = useState(INITIAL_CARD_ERROR_STATE);

  return (
    <ErrorContext.Provider
      value={{
        cardError,
        setCardError,
      }}>
      {children}
    </ErrorContext.Provider>
  );
};

ErrorContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
