export const {UNLEASH_PROXY_HOST, UNLEASH_CLIENT_KEY} = process.env;

const UNLEASH_APP_NAME = 'fe-registry-cash-checkout';

export const UNLEASH_REFRESH_INTERVAL_SECS = 30;

export const UNLEASH_CONFIG = {
  url: `${UNLEASH_PROXY_HOST}/proxy`,
  clientKey: UNLEASH_CLIENT_KEY,
  refreshInterval: UNLEASH_REFRESH_INTERVAL_SECS,
  appName: UNLEASH_APP_NAME,
  /*
   * define the bootstrap from the main unleash-proxy-client IToggle interface
   *
   * @link https://github.com/Unleash/unleash-proxy-client-js/blob/main/src/index.ts#LL37C17-L37C24
   * @link https://github.com/Unleash/unleash-proxy-client-js/blob/main/src/index.ts#L54-L59
   */
  bootstrap: [
    {
      enabled: true,
      name: `${UNLEASH_APP_NAME}-offline`,
      /* use the defaultVariant from the main unleash-proxy-client IVariant interface
       *
       * @link https://github.com/Unleash/unleash-proxy-client-js/blob/main/src/index.ts#L45-L52
       * @link https://github.com/Unleash/unleash-proxy-client-js/blob/main/src/index.ts#L74
       */
      variant: {
        name: 'disabled',
        enabled: false,
      },
    },
  ],
};

export const FEATURES = {
  OFFER_AD_SECTION: 'reg-offer-ad',
};
