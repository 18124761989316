import React from 'react';
import style from './style.scss';

const ContactInformation = () => (
  <div
    className={style['contact-information-container']}
    data-testid="contact-information-container">
    <p className={style['contact-information-title']}>Contact Information</p>
    <p className={style['contact-information-item']}>
      Email:&nbsp;
      <a
        className={style['contact-information-link']}
        href="mailto:help@theknot.com">
        help@theknot.com
      </a>
    </p>
    <p className={style['contact-information-item']}>
      Phone:&nbsp;
      <a className={style['contact-information-link']} href="tel:877-843-5668">
        877-843-5668
      </a>
    </p>
    <p className={style['contact-information-item']}>
      M-F | 9:00am - 6:00pm EST
    </p>
  </div>
);

export default ContactInformation;
