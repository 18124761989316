import '@xo-union/tk-ui-essentials';
import React, {useContext, useEffect} from 'react';
import Honeybadger from 'honeybadger-js';
import {Formik} from 'formik';
import isEmpty from 'lodash.isempty';
import CheckoutForm from '../../components/CheckoutForm';
import {AppContext} from '../../contexts/AppContext';
import {CheckoutContext} from '../../contexts/CheckoutContext';
import {
  ErrorContext,
  INITIAL_CARD_ERROR_STATE,
} from '../../contexts/ErrorContext';
import createPurchase from '../../utils/createPurchase';
import {
  convertDollarToCents,
  convertCentsToDollars,
  getGiftAmount,
  getTotalDollar,
  getTransactionFeeDollar,
} from '../../utils/getAmounts';
import getStripeTokenId from '../../utils/getStripeTokenId';
import trackCashCheckout from '../../utils/trackCashCheckout';
import {getInitialValues, getValidationSchema} from './validationSchema';

const Checkout = () => {
  const {cashFund, quantity, setQuantity} = useContext(AppContext);
  const {
    giftAmount,
    setGiftAmount,
    setOrderNumber,
    setShowConfirmation,
    setTotal,
    setTransactionFee,
    total,
  } = useContext(CheckoutContext);
  const {setCardError} = useContext(ErrorContext);

  useEffect(() => {
    setGiftAmount(getGiftAmount(quantity, cashFund));
    setTotal(getTotalDollar(quantity, cashFund));
    setTransactionFee(getTransactionFeeDollar(quantity, cashFund));
  }, [cashFund, quantity, setGiftAmount, setTotal, setTransactionFee]);

  const handlePurchase = async (values, actions) => {
    const {email, name, note} = values;
    const amount = convertDollarToCents(giftAmount);
    const source = await getStripeTokenId(values, () => {
      setCardError({
        message: 'Sorry, your card was declined.',
        subText:
          'Please try entering your information again or using a new card.',
      });

      actions.setSubmitting(false);
    });

    try {
      const {body, status} = await createPurchase({
        amount,
        email,
        fundId: cashFund.id,
        memberId: cashFund.memberId,
        name,
        note,
        source,
      });

      if (status === 200) {
        const {segmentsPurchased, orderNumber: purchaseOrderNumber} = body;

        setCardError(INITIAL_CARD_ERROR_STATE);
        setOrderNumber(purchaseOrderNumber);
        setQuantity(convertCentsToDollars(segmentsPurchased));
        setShowConfirmation(true);

        trackCashCheckout({
          event: 'Cash Checkout Interaction',
          giftsTotal: values.quantity,
          personalNote: values.note !== '',
          selection: 'purchase fund',
          source: 'cash checkout form',
          sourceFundId: cashFund.cashFundTemplateId,
          transactionTotal: total,
          tag: cashFund.topChoice ? 'topChoice' : 'none',
        });
      } else {
        trackCashCheckout({
          event: 'Cash Guest Error',
          source: 'cash checkout form',
          action: 'createPurchase',
          statusCode: status,
          message: body.message,
        });

        if (status === 409) {
          const {data} = body;
          const {segmentsRemaining} = data;

          setCardError({
            message:
              typeof segmentsRemaining === 'number' && segmentsRemaining > 0
                ? `Only $${convertCentsToDollars(segmentsRemaining)} of ${
                    cashFund.name
                  } is needed. Adjust the quantity to continue.`
                : `${cashFund.name} has already been purchased by someone else.`,
            subText: '',
          });
        } else {
          const {message} = body;

          Honeybadger.notify(
            new Error(`createPurchase: ${message}. Status: ${status}`),
          );

          setCardError({
            message,
            subText: '',
          });
        }
      }
    } catch (err) {
      Honeybadger.notify(new Error(`handlePurchase: ${err}`));

      setCardError({
        message:
          'Failed to process payment. Contact Customer Service at (877) 843-5668 if the problem persists.',
        subText: '',
      });
    }

    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={getInitialValues(quantity)}
      onSubmit={(values, actions) => handlePurchase(values, actions)}
      validationSchema={getValidationSchema(
        cashFund.fundType,
        cashFund.segmentsRequested - cashFund.segmentsReceived,
      )}>
      {({errors, isSubmitting, setFieldTouched, values}) => {
        setQuantity(values.quantity);

        return (
          <CheckoutForm
            isPurchaseDisabled={isSubmitting || !isEmpty(errors)}
            setFieldTouched={setFieldTouched}
          />
        );
      }}
    </Formik>
  );
};

export default Checkout;
