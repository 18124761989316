const trackBillingAddressEntered = ({orderNumber, cashFund}) => {
  window.analytics.track({
    event: 'Billing Address Entered',
    properties: {
      affiliation: cashFund.affiliation,
      order_id: orderNumber || null,
      step: 2,
      checkout_id: orderNumber || null,
      shipping_method: 'digital',
      payment_method: 'credit',
      wishlist_id: cashFund.memberId || undefined,
    },
  });
};
export default trackBillingAddressEntered;
