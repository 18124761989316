/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import valid from 'card-validator';
import {FIXED_FUND} from '../../constants';
import {getNumberFromString} from '../../utils/getAmounts';

const CREDIT_CARD_REGEX = /(\d{4}\s*\d{4}\s*\d{4}\s*\d{4})|(\d{4}\s*\d{6}\s*\d{4})|(\d{16})/;
const SENSITIVE_DATA_WARNING =
  'It looks like you might have entered sensitive information, like a credit card or bank account number, in your gift note. To protect yourself, you should remove any sensitive information before placing your order.';

export const getInitialValues = quantity => ({
  address: '',
  card: '',
  city: '',
  cvv: '',
  email: '',
  expiration: '',
  isTermsAccepted: false,
  name: '',
  note: '',
  quantity,
  state: '',
  zip: '',
});

export const getValidationSchema = (fundType = '', numRemaining = 0) =>
  Yup.object().shape({
    address: Yup.string()
      .trim()
      .max(100, 'Please limit your billing address to ${max} characters.')
      .required('Please enter a valid billing address.'),
    card: Yup.string()
      .test(
        'card',
        'Please enter a valid credit card number.',
        value => valid.number(value).isValid,
      )
      .required(),
    city: Yup.string()
      .trim()
      .max(100, 'Please limit your city name to ${max} characters.')
      .required('Please enter a valid city.'),
    cvv: Yup.string()
      .test(
        'cvv',
        'Invalid CVV.',
        value => valid.cvv(value, 3).isValid || valid.cvv(value, 4).isValid,
      )
      .required(),
    email: Yup.string()
      .email('Please enter a valid email address.')
      .required(),
    expiration: Yup.string()
      .test(
        'expiration',
        'Invalid expiration.',
        value => valid.expirationDate(value).isValid,
      )
      .required(),
    isTermsAccepted: Yup.bool().oneOf(
      [true],
      'You must read and agree to these terms and conditions.',
    ),
    name: Yup.string()
      .trim()
      .max(100, 'Please limit your name to ${max} characters.')
      .required(
        'Please enter the name exactly as it appears on the credit card.',
      ),
    note: Yup.string()
      .trim()
      .max(500, 'Please limit your message to ${max} characters.')
      .test('note', SENSITIVE_DATA_WARNING, value => {
        const hasSensitiveData = value?.match(CREDIT_CARD_REGEX);
        return !hasSensitiveData;
      }),
    quantity: Yup.string()
      .test(
        'currencyMin',
        fundType === FIXED_FUND
          ? 'Please enter at least 1.'
          : 'Please enter at least $1.',
        currencyValue =>
          fundType === FIXED_FUND
            ? currencyValue > 0
            : getNumberFromString(currencyValue) > 0,
      )
      .test(
        'currencyMin',
        fundType === FIXED_FUND
          ? `Please enter less than ${numRemaining}.`
          : 'Please enter less than $1,000.',
        currencyValue =>
          fundType === FIXED_FUND
            ? getNumberFromString(currencyValue) <= numRemaining
            : getNumberFromString(currencyValue) <= 1000,
      )
      .required(),
    state: Yup.string().required('Required.'),
    zip: Yup.string()
      .test(
        'zip',
        'Please enter a valid zip code.',
        value => valid.postalCode(value).isValid,
      )
      .required(),
  });
