const fetchCashFund = async fundId => {
  const response = await fetch(
    `${process.env.CASH_API_HOST}/v1/funds/${fundId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return response.json();
};

export default fetchCashFund;
