import {FIXED_FUND, TRANSACTION_FEE_PERCENTAGE} from '../constants';

const CENTS = 100;

export const convertDollarToCents = amount =>
  Number(amount.replace(/[^0-9.-]+/g, '')) * CENTS;
export const convertCentsToDollars = priceCents => priceCents / CENTS;

const getDollarAmount = value =>
  value &&
  value.toLocaleString('en-US', {
    currency: 'USD',
    style: 'currency',
  });

export const getNumberFromString = numericString =>
  numericString && typeof numericString !== 'number'
    ? Number(numericString.replace(/[^0-9.-]+/g, ''))
    : numericString;

const getQuantityAmount = (quantity, cashFund) => {
  const {fundType, priceCents} = cashFund;
  const isFixed = fundType && fundType === FIXED_FUND;

  return isFixed
    ? quantity * convertCentsToDollars(priceCents)
    : getNumberFromString(quantity);
};

export const getGiftAmount = (quantity, cashFund) =>
  getDollarAmount(getQuantityAmount(quantity, cashFund));

export const getTransactionFee = (quantity, cashFund) =>
  getQuantityAmount(quantity, cashFund) * TRANSACTION_FEE_PERCENTAGE;

export const getTransactionFeeDollar = (quantity, cashFund) =>
  getDollarAmount(getTransactionFee(quantity, cashFund));

export const getTotal = (quantity, cashFund) =>
  getQuantityAmount(quantity, cashFund) + getTransactionFee(quantity, cashFund);

export const getTotalDollar = (quantity, cashFund) =>
  getDollarAmount(getTotal(quantity, cashFund));
