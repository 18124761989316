import {useContext} from 'react';
import {AppContext} from '../contexts/AppContext';
import mapFundsForAnalytics from './mapFundsForAnalytics';
import {getNumberFromString, getTotal} from './getAmounts';

const useTrackOrderCompleted = (
  orderNumber,
  total,
  giftAmount,
  transactionFee,
) => {
  const {cashFund, quantity} = useContext(AppContext);
  window.analytics.track({
    event: 'Order Completed',
    properties: {
      affiliation: cashFund.affiliation,
      checkout_id: orderNumber || null,
      currency: 'USD',
      quantity: Number(quantity),
      revenue: Number(getTotal(quantity, cashFund).toFixed(2)),
      subtotal: getNumberFromString(giftAmount),
      tax: getNumberFromString(transactionFee),
      total: Number(getTotal(quantity, cashFund).toFixed(2)),
      products: mapFundsForAnalytics({cashFund, quantity}),
      wishlist_id: cashFund.memberId || undefined,
    },
  });
};

export default useTrackOrderCompleted;
