import React from 'react';
import PropTypes from 'prop-types';
import {Button} from '@xo-union/tk-component-buttons';
import generateTestId from '../../utils/generateTestId';
import style from './style.scss';

const PurchaseButton = ({isDisabled}) => (
  <div
    className={style['purchase-button-container']}
    data-testid={generateTestId('purchase-button-container')}>
    <Button
      color="primary"
      data-testid={generateTestId('purchase-button')}
      disabled={isDisabled}
      size="md"
      type="submit">
      Purchase Gift
    </Button>
    <span className={style['purchase-button-info']}>
      The charge will appear as “The Knot Registry” on your credit card
      statement.
    </span>
  </div>
);

PurchaseButton.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
};

export default PurchaseButton;
