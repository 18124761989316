import React, {createContext, useState} from 'react';
import PropTypes from 'prop-types';

export const CheckoutContext = createContext({
  giftAmount: '',
  orderNumber: '',
  showConfirmation: false,
  setGiftAmount: () => {},
  setOrderNumber: () => {},
  setShowConfirmation: () => {},
  setTotal: () => {},
  setTransactionFee: () => {},
  total: '',
  transactionFee: '',
});

export const CheckoutContextProvider = ({children}) => {
  const [giftAmount, setGiftAmount] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [total, setTotal] = useState('');
  const [transactionFee, setTransactionFee] = useState('');

  return (
    <CheckoutContext.Provider
      value={{
        giftAmount,
        orderNumber,
        showConfirmation,
        setGiftAmount,
        setOrderNumber,
        setShowConfirmation,
        setTotal,
        setTransactionFee,
        total,
        transactionFee,
      }}>
      {children}
    </CheckoutContext.Provider>
  );
};

CheckoutContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
